import React from 'react'

export const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404: Not found</h1>
      <p>sorry :(</p>
      <a href="/">[go back]</a>
    </div>
  )
}
